import React from 'react';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import WithMeta from "../utils/WithMeta";
import { withTranslation } from "react-i18next";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
    ${tw`text-lg text-gray-800`}
    p {
        ${tw`mt-2 leading-loose`}
    }
    h1 {
        ${tw`text-3xl font-bold mt-10`}
    }
    h2 {
        ${tw`text-2xl font-bold mt-8`}
    }
    h3 {
        ${tw`text-xl font-bold mt-6`}
    }
    ul {
        ${tw`list-disc list-inside`}
        li {
            ${tw`ml-2 mb-3`}
            p {
                ${tw`mt-0 inline leading-normal`}
            }
        }
    }
`;

function TermsOfService({ t }) {
    return (
        <>
            <WithMeta
                title={t('termsOfService.metaTitle')}
                description={t('termsOfService.metaDescription')}
                url={t('termsOfService.metaUrl')}
            />
            <AnimationRevealPage>
                <Header />
                <Container>
                    <ContentWithPaddingXl>
                        <HeadingRow>
                            <Heading>{t('termsOfService.title')}</Heading>
                        </HeadingRow>
                        <Text>
                            <h1>{t('termsOfService.mainTitle')}</h1>
                            <p><strong>{t('termsOfService.lastUpdated')}</strong></p>
                            <p>{t('termsOfService.intro')}</p>
                            <p>{t('termsOfService.acceptance')}</p>
                            <p>{t('termsOfService.modifications')}</p>

                            <h2>{t('termsOfService.userAccountTitle')}</h2>
                            <p>{t('termsOfService.userAccountDescription')}</p>

                            <h2>{t('termsOfService.paymentSavingTitle')}</h2>
                            <p>{t('termsOfService.paymentSavingDescription')}</p>

                            <h2>{t('termsOfService.serviceUsageTitle')}</h2>
                            <p>{t('termsOfService.serviceUsageDescription')}</p>

                            <ul>
                                <li>{t('termsOfService.serviceUsagePoint1')}</li>
                                <li>{t('termsOfService.serviceUsagePoint2')}</li>
                                <li>{t('termsOfService.serviceUsagePoint3')}</li>
                                <li>{t('termsOfService.serviceUsagePoint4')}</li>
                                <li>{t('termsOfService.serviceUsagePoint5')}</li>
                            </ul>

                            <h3>{t('termsOfService.menuAccessTitle')}</h3>
                            <p>{t('termsOfService.menuAccessDescription')}</p>

                            <h3>{t('termsOfService.billPaymentTitle')}</h3>
                            <p>{t('termsOfService.billPaymentDescription')}</p>

                            <h3>{t('termsOfService.tippingTitle')}</h3>
                            <p>{t('termsOfService.tippingDescription')}</p>

                            <h2>{t('termsOfService.refusalServiceTitle')}</h2>
                            <p>{t('termsOfService.refusalServiceDescription')}</p>

                            <h2>{t('termsOfService.intellectualPropertyTitle')}</h2>
                            <p>{t('termsOfService.intellectualPropertyDescription')}</p>

                            <h2>{t('termsOfService.liabilityTitle')}</h2>
                            <p>{t('termsOfService.liabilityDescription')}</p>

                            <h2>{t('termsOfService.applicableLawTitle')}</h2>
                            <p>{t('termsOfService.applicableLawDescription')}</p>

                            <p>{t('termsOfService.closingRemark')} <a href="mailto:contact@owneat.fr">contact@owneat.fr</a></p>
                        </Text>
                    </ContentWithPaddingXl>
                </Container>
                <Footer />
            </AnimationRevealPage>
        </>
    );
}

export default withTranslation()(TermsOfService);
