import { css } from "styled-components/macro"; //eslint-disable-line

const defaultPlans = (t) => [{
    name: t("pricing.features.discovery.name"),
    price: ["€49", ".99 HT"],
    fees: false,
    feesAmount: "",
    duration: t("pricing.duration"),
    mainFeature: t("pricing.features.discovery.mainFeature"),
    features: [
        t("pricing.features.discovery.features.0"),
        t("pricing.features.discovery.features.1"),
        t("pricing.features.discovery.features.2"),
        t("pricing.features.discovery.features.3"),
        t("pricing.features.discovery.features.4"),
        t("pricing.features.discovery.features.5"),
    ],
},
{
    name: t("pricing.features.essential.name"),
    price: ["€74", ".99 HT"],
    fees: false,
    feesAmount: "",
    duration: t("pricing.duration"),
    mainFeature: t("pricing.features.essential.mainFeature"),
    features: [
        t("pricing.features.essential.features.0"),
        t("pricing.features.essential.features.1"),
        t("pricing.features.essential.features.2"),
        t("pricing.features.essential.features.3"),
        t("pricing.features.essential.features.4"),
        t("pricing.features.essential.features.5"),
        t("pricing.features.essential.features.6"),
        t("pricing.features.essential.features.7"),
    ],
    featured: true,
},
{
    name: t("pricing.features.integral.name"),
    price: ["€99", ".99 HT"],
    fees: true,
    feesAmount: "0.7% / transaction",
    duration: t("pricing.duration"),
    mainFeature: t("pricing.features.integral.mainFeature"),
    features: [
        t("pricing.features.integral.features.0"),
        t("pricing.features.integral.features.1"),
        t("pricing.features.integral.features.2"),
        t("pricing.features.integral.features.3"),
        t("pricing.features.integral.features.4"),
        t("pricing.features.integral.features.5"),
        t("pricing.features.integral.features.6"),
    ],
}];

const highlightGradientsCss = [
    css`
      background: rgb(56, 178, 172);
      background: linear-gradient(115deg, #63ea83 0%, rgba(129, 230, 217, 1) 100%);
    `,
    css`
      background: rgb(56, 178, 172);
      background-image: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
    `,
    css`
      background: rgb(245, 101, 101);
      background: linear-gradient(115deg, rgba(245, 101, 101, 1) 0%, rgba(254, 178, 178, 1) 100%);
    `
  ];

export { defaultPlans, highlightGradientsCss };